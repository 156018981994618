import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";

import TeamsTable from "../Teams/TeamsTable";
import FiltersBar from "../../components/filters/FiltersBar";
import TlPaper from "../../components/TlPaper";
import {
  getFiltersFromUrl,
  prepareFilters,
} from "../../components/filters/prepareFilters";
import { teamStatuses } from "../../utils/dictionaries";
import axios from "../../utils/axios";

export default function CoachTeams(props) {
  const [filters, setFilters] = useState({
    status: "active",
    season: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [teams, setTeams] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const location = useLocation();

  const filtersStructure = useMemo(
    () => [
      {
        name: "season",
        type: "select",
        options: _.orderBy(props.seasons, "year", "desc").reduce(
          (prev, curr) => {
            prev[curr._id] = curr.name;
            return prev;
          },
          {}
        ),
        emptyLabel: "כל העונות",
        label: "עונה",
        includeAll: true,
      },
      {
        name: "status",
        type: "select",
        options: teamStatuses,
        emptyLabel: "כל הסטטוסים",
        label: "סטטוס",
      },
    ],
    [props.seasons, props.clubCategory]
  );

  useEffect(() => {
    const currentFilters = getFiltersFromUrl(
      location,
      filters,
      filtersStructure
    );

    const newFilters = {
      ...currentFilters,
      season: filters.season || props.seasons.find((s) => s.main)?._id,
    };

    setFilters(newFilters);
    loadData(newFilters);
  }, [filtersStructure]);

  const loadData = async (newFilters) => {
    const currentFilters = newFilters || filters;
    setIsLoading(true);
    let filtersBase = prepareFilters(currentFilters, filtersStructure, {
      includeAll: false,
    });
    const filtersUrl = filtersBase.join("&");

    const res = await axios.get(
      `/teams?clubCoachId=${props.coachId}${
        filtersBase.length > 0 ? "&" + filtersUrl : ""
      }&granularity=clubCoach&granularity=playersStats&granularity=seasonYear&granularity=assistants`
    );

    setTeams(_.sortBy(res.data, "name"));
    setIsLoading(false);
    setInitialLoading(false);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters({ ...filters, [filterName]: value });
  };

  return (
    <TlPaper title="קבוצות" titleBackground isLoading={initialLoading}>
      <FiltersBar
        filters={filtersStructure || []}
        onFilterChanged={handleFilterChange}
        values={filters}
        onSearch={loadData}
        isSearching={isLoading}
      />
      <TeamsTable context="coach" teams={teams} />
    </TlPaper>
  );
}
