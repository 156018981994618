import React from "react";
import {
  DialogContent,
  DialogActions,
  Grid,
  CircularProgress,
  Switch,
  Typography,
  Alert,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";

import axios from "../../../../utils/axios";
import MenuItem from "../../../../components/base/MenuItem";
import FormTextField from "../../../../components/base/FormTextField";
import Dialog from "../../../../components/Dialog";
import { displaySeason, roundPrice } from "../../../../utils/helpers";
import SubscriptionProductFeesForm from "./SubscriptionProductFeesForm";
import ButtonSx from "../../../../components/base/ButtonSx";
import Paper2Sx from "../../../../components/Paper2Sx";
import withBreakpoints from "../../../../components/routing/withBreakpoints";
import SubscriptionProductStartDate from "./SubscriptionProductStartDate";
import SubscriptionProductEndDate from "./SubscriptionProductEndDate";
import { maxPeriod, validate } from "./subscriptionProductsHelper";
import SubscriptionProductPriceFields from "./SubscriptionProductPriceFields";
import SubscriptionProductPricingsForm from "./SubscriptionProductPricingsForm";
import SubscriptionProductCostsSummary from "./SubscriptionProductCostsSummary";

class EditSubscriptionProductDialog extends React.Component {
  state = {
    isLoading: false,
    instantValidate: false,
    seasons: null,
    errors: {},
    subscriptionProduct: null,
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });

    const res = await axios.get(
      "/settings/seasons?canCreateTeamsAndProducts=true"
    );
    this.setState({
      seasons: _.orderBy(res.data, "year", "desc"),
      isLoading: false,
    });
  };

  componentDidUpdate = async (prevProps) => {
    let startDate;
    let completed = false;
    let editable = true;
    let seasonNotInList = false;
    if (!prevProps.open && this.props.open) {
      let subscriptionProduct;
      if (this.props.subscriptionProduct) {
        const { subscriptionDetails } = this.props.subscriptionProduct;

        seasonNotInList =
          this.state.seasons.find(
            (s) => s._id === this.props.subscriptionProduct.season._id
          ) === undefined;

        subscriptionProduct = {
          ..._.pick(this.props.subscriptionProduct, [
            "title",
            "active",
            "accountingExportNumber",
          ]),
          ..._.pick(subscriptionDetails, ["subscriptionType"]),
        };

        // get rid of the timezone. we need the start and end dates to be 00:00 in israel timezone
        subscriptionProduct.startDate = moment(
          moment(subscriptionDetails.startDate).format("YYYY-MM-DD")
        );
        subscriptionProduct.endDate = moment(
          moment(subscriptionDetails.endDate).format("YYYY-MM-DD")
        );

        completed = subscriptionProduct.endDate.isBefore(
          moment().startOf("day")
        );
        subscriptionProduct.season = this.props.subscriptionProduct.season._id;
        editable = this.props.subscriptionProduct.editable && !completed;

        switch (subscriptionProduct.subscriptionType) {
          case "season":
          case "camp":
            if (subscriptionProduct.endDate && subscriptionProduct.startDate) {
              subscriptionProduct.months =
                moment(subscriptionProduct.endDate).diff(
                  moment(subscriptionProduct.startDate),
                  "month"
                ) + 1;
            } else {
              subscriptionProduct.months = 0;
            }
            break;

          case "daily":
            subscriptionProduct.units =
              moment(subscriptionProduct.endDate).diff(
                moment(subscriptionProduct.startDate),
                "days"
              ) + 1;
            break;

          default:
            break;
        }

        subscriptionProduct.numberOfPayments =
          subscriptionDetails.numberOfPayments || "";

        subscriptionProduct.parentsPriceAutoUpdate = this.props
          .subscriptionProduct.parentsPriceAutoUpdate
          ? "true"
          : "false";

        subscriptionProduct.singlePricing = subscriptionDetails.singlePricing;
        subscriptionProduct.pricings = subscriptionDetails.pricings.map(
          (p) => ({
            ...p,
            id: p._id,
            unitPriceStr: p.unitPrice?.toString(),
            quantityStr: p.quantity?.toString(),
          })
        );

        subscriptionProduct.fees = (
          this.props.subscriptionProduct.subProducts || []
        ).map((subProduct) => {
          return {
            price: subProduct.price,
            priceStr: subProduct.price,
            description: subProduct.title,
            aen: subProduct.accountingExportNumber,
            _id: subProduct._id,
            deliverable: subProduct.deliverable,
          };
        });
        subscriptionProduct.feesTotal = roundPrice(
          _.sumBy(subscriptionProduct.fees, "price")
        );
      } else {
        subscriptionProduct = {
          title: "",
          active: true,
          parentsPriceAutoUpdate: "false",
          startDate: "",
          endDate: "",
          months: "",
          subscriptionType: "",
          numberOfPayments: "",
          accountingExportNumber: "",
          fees: [],
          season: "",
          pricings: [],
          units: "",
          singlePricing: "",
        };
      }

      this.setState({
        subscriptionProduct,
        startDate,
        instantValidate: false,
        completed,
        editable,
        seasonNotInList,
        errors: {},
      });
    }
  };

  handleFeesChange = (fees) => {
    this.handleFieldChange({ fees });
  };

  handleFieldChange = async (updates) => {
    const subscriptionProduct = {
      ...this.state.subscriptionProduct,
      ...updates,
    };

    // manually handled
    delete subscriptionProduct.unitPrice;

    if (Object.prototype.hasOwnProperty.call(updates, "subscriptionType")) {
      subscriptionProduct.startDate = "";
      subscriptionProduct.endDate = "";
      subscriptionProduct.parentsPriceAutoUpdate = "false";
      subscriptionProduct.months = "";
      // season can be both
      // fixed can be both
      // camp is always single price
      // daily is always multiple prices
      subscriptionProduct.pricings = [];
      subscriptionProduct.singlePricing = "";
      if (updates.subscriptionType === "camp") {
        subscriptionProduct.pricings = [{ unitPrice: "" }];
        subscriptionProduct.singlePricing = true;
      } else if (updates.subscriptionType === "daily") {
        subscriptionProduct.singlePricing = false;
      }
    }

    if (Object.prototype.hasOwnProperty.call(updates, "singlePricing")) {
      if (updates.singlePricing) {
        subscriptionProduct.pricings = [{ unitPrice: "" }];
      } else {
        subscriptionProduct.pricings = [];
      }
    }

    if (Object.prototype.hasOwnProperty.call(updates, "unitPrice")) {
      subscriptionProduct.pricings[0].unitPrice = parseInt(updates.unitPrice);
    }

    // if updating the start date and it's after the end date or the total period is more than maxPeriod
    if (Object.prototype.hasOwnProperty.call(updates, "startDate")) {
      if (
        moment(subscriptionProduct.startDate).isAfter(
          moment(subscriptionProduct.endDate)
        ) ||
        moment(subscriptionProduct.startDate)
          .add(maxPeriod, "months")
          .isBefore(moment(subscriptionProduct.endDate))
      ) {
        subscriptionProduct.endDate = "";
      }
    }

    if (
      Object.prototype.hasOwnProperty.call(updates, "startDate") ||
      Object.prototype.hasOwnProperty.call(updates, "endDate")
    ) {
      switch (subscriptionProduct.subscriptionType) {
        case "season":
        case "camp":
          if (subscriptionProduct.endDate && subscriptionProduct.startDate) {
            subscriptionProduct.months =
              moment(subscriptionProduct.endDate).diff(
                moment(subscriptionProduct.startDate),
                "month"
              ) + 1;
          } else {
            subscriptionProduct.months = 0;
          }
          break;

        case "daily":
          subscriptionProduct.units =
            moment(subscriptionProduct.endDate).diff(
              moment(subscriptionProduct.startDate),
              "days"
            ) + 1;
          break;

        default:
          break;
      }
    }

    if (Object.prototype.hasOwnProperty.call(updates, "fees")) {
      subscriptionProduct.feesTotal = roundPrice(
        _.sumBy(subscriptionProduct.fees, "price")
      );
    }

    await this.setState({ subscriptionProduct });
    if (this.state.instantValidate) {
      const { errors } = validate(
        this.state.subscriptionProduct,
        this.props.requireAccountingExportNumber
      );
      this.setState({ errors });
    }
  };

  handleSave = async () => {
    const { subscriptionProduct } = this.state;
    const { isValid, errors } = validate(
      subscriptionProduct,
      this.props.requireAccountingExportNumber
    );
    this.setState({ errors, instantValidate: true });

    if (isValid) {
      this.setState({ isLoading: true });

      const data = {
        ...subscriptionProduct,
        startDate: subscriptionProduct.startDate.format("YYYY-MM-DD"),
        endDate: subscriptionProduct.endDate.format("YYYY-MM-DD"),
        pricings: subscriptionProduct.pricings.map((p) =>
          _.pick(p, [
            "_id",
            "unitPrice",
            "title",
            "quantity",
            "active",
            "activeForParents",
          ])
        ),
      };

      data.fees = subscriptionProduct.fees.map((fee) => ({
        _id: fee._id.startsWith("local") ? undefined : fee._id,
        price: fee.price,
        aen: fee.aen.trim(),
        description: fee.description.trim(),
        deliverable: fee.deliverable,
      }));

      data.parentsPriceAutoUpdate = data.parentsPriceAutoUpdate === "true";
      if (this.props.subscriptionProduct) {
        await axios.patch(
          `/store/subscriptionProducts/${this.props.subscriptionProduct._id}`,
          data
        );
      } else {
        await axios.post(`/store/subscriptionProducts`, data);
      }

      this.setState({ isLoading: false });
      this.props.onComplete();
    }
  };

  renderDateOptions = (startMonth, total, type) => {
    const items = [];
    for (let index = 0; index < total; index++) {
      const month = moment(startMonth);
      month.add(index, "month");
      if (type === "start") {
        month.startOf("month");
      } else {
        month.endOf("month");
      }
      items.push(
        <MenuItem key={index} value={month.format("YYYY-MM-DD")}>
          <Grid container spacing={1} dir="rtl">
            <Grid item>{month.format("MM/YY")}</Grid>
            {type === "end" && <Grid item>({index + 1} חודשים)</Grid>}
          </Grid>
        </MenuItem>
      );
    }
    return items;
  };

  renderContent = () => {
    const defaultTextFieldParams = {
      form: this.state.subscriptionProduct,
      onChange: this.handleFieldChange,
      errors: this.state.errors,
      textFieldProps: {
        inputProps: {
          readOnly: this.state.completed,
        },
      },
      defaultHelperText: " ",
    };

    const { editable } = this.state;

    return (
      <Grid container spacing={2}>
        {this.state.completed && (
          <Grid item xs={12} sx={styles.gridItem}>
            <Alert severity="info">
              לא ניתן לערוך מסגרת זו מאחר והיא הסתיימה
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Paper2Sx
            externalTitle="כללי"
            sx={styles.generalPaper}
            paperSx={styles.generalPaper}
            fullHeight
          >
            <Grid container>
              <Grid item xs={12} md={6} sx={styles.gridItem}>
                <FormTextField
                  label="סוג מסגרת"
                  field="subscriptionType"
                  {...defaultTextFieldParams}
                  textFieldProps={{
                    select: true,
                    error: this.state.errors.subscriptionType !== undefined,
                    helperText:
                      this.props.subscriptionProduct === null
                        ? this.state.errors.subscriptionType || " "
                        : "לא ניתן לשנות את סוג המסגרת",
                    inputProps: {
                      readOnly: this.props.subscriptionProduct !== null,
                    },
                  }}
                >
                  <MenuItem value="season">מחיר חודשי</MenuItem>
                  {this.props.subscriptionProduct && (
                    <MenuItem value="camp">מחנה</MenuItem>
                  )}
                  <MenuItem value="fixedPrice">מחיר קבוע</MenuItem>
                  <MenuItem value="daily">מחיר יומי</MenuItem>
                </FormTextField>
              </Grid>
              {this.props.requireAccountingExportNumber && (
                <Grid item xs={12} md={6} sx={styles.gridItem}>
                  <FormTextField
                    label="מפתח חשבון"
                    field="accountingExportNumber"
                    {...defaultTextFieldParams}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6} sx={styles.gridItem}>
                <FormTextField
                  label="עונה"
                  field="season"
                  {...defaultTextFieldParams}
                  textFieldProps={{
                    select: true,
                    inputProps: { readOnly: !editable },
                  }}
                >
                  {this.state.seasons.map((season) => (
                    <MenuItem key={season._id} value={season._id}>
                      {displaySeason(season.year)}
                    </MenuItem>
                  ))}
                  {this.state.seasonNotInList &&
                    this.props.subscriptionProduct && (
                      <MenuItem
                        key={this.props.subscriptionProduct.season._id}
                        value={this.props.subscriptionProduct.season._id}
                      >
                        {displaySeason(
                          this.props.subscriptionProduct.season.year
                        )}
                      </MenuItem>
                    )}
                </FormTextField>
              </Grid>
              <Grid item xs={12} md={6} sx={styles.gridItem}>
                <FormTextField
                  label="שם המסגרת"
                  field="title"
                  {...defaultTextFieldParams}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={styles.gridItem}>
                <SubscriptionProductStartDate
                  subscriptionProduct={this.state.subscriptionProduct}
                  onChange={this.handleFieldChange}
                  editable={editable}
                  errors={this.state.errors}
                />
              </Grid>
              {["season", "camp", ""].indexOf(
                this.state.subscriptionProduct.subscriptionType
              ) > -1 && (
                <Grid item xs={12} md={6} sx={styles.gridItem}>
                  <SubscriptionProductEndDate
                    subscriptionProduct={this.state.subscriptionProduct}
                    onChange={this.handleFieldChange}
                    editable={editable}
                    errors={this.state.errors}
                  />
                </Grid>
              )}

              <SubscriptionProductPriceFields
                defaultTextFieldParams={defaultTextFieldParams}
                subscriptionProduct={this.state.subscriptionProduct}
                editable={editable}
                completed={this.state.completed}
                errors={this.state.errors}
              />
            </Grid>
          </Paper2Sx>
        </Grid>

        <Grid item xs={12} md={6} sx={{ display: "flex", flex: 1 }} container>
          <Grid item xs={12}>
            <SubscriptionProductFeesForm
              onFeesChanged={this.handleFeesChange}
              subscriptionProduct={this.state.subscriptionProduct}
              requireAccountingExportNumber={
                this.props.requireAccountingExportNumber
              }
              errors={this.state.errors}
              editable={editable}
              completed={this.state.completed}
              maxFees={
                this.props.selectedClub.clubConfig.maxSubscriptionProductFees
              }
            />
          </Grid>
          <Grid item xs={12}>
            {this.state.subscriptionProduct.singlePricing === false && (
              <SubscriptionProductPricingsForm
                subscriptionProduct={this.state.subscriptionProduct}
                editable={editable}
                onChange={this.handleFieldChange}
                errors={this.state.errors}
                completed={this.state.completed}
              />
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SubscriptionProductCostsSummary
            subscriptionProduct={this.state.subscriptionProduct}
          />
        </Grid>
      </Grid>
    );
  };

  renderTitle = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span style={{ flexGrow: 1 }}>
        עריכת מסגרת{" "}
        {process.env.NODE_ENV === "development" &&
          `${this.props.subscriptionProduct?._id} - editable: ${this.props.subscriptionProduct?.editable}`}
      </span>

      <Typography variant="subtitle1">
        {this.state.subscriptionProduct ? (
          <Grid container alignItems="center" sx={{ pr: 5 }}>
            <Grid item>לא פעיל</Grid>
            <Grid item>
              <Switch
                checked={this.state.subscriptionProduct.active}
                name="active"
                onChange={() =>
                  this.handleFieldChange({
                    active: !this.state.subscriptionProduct.active,
                  })
                }
                disabled={this.state.completed}
              />
            </Grid>
            <Grid item>פעיל</Grid>
          </Grid>
        ) : (
          <span />
        )}
      </Typography>
    </div>
  );

  render() {
    return (
      <Dialog
        onClose={this.props.onClose}
        open={this.props.open}
        title={this.renderTitle()}
        fullScreen
        closeButton
      >
        <DialogContent dividers sx={{ px: 1 }}>
          {this.state.subscriptionProduct ? (
            this.renderContent()
          ) : (
            <CircularProgress />
          )}
        </DialogContent>

        <DialogActions>
          <ButtonSx
            onClick={this.props.onClose}
            disabled={this.state.isLoading}
          >
            {this.state.completed ? "סגירה" : "ביטול"}
          </ButtonSx>
          {!this.state.completed && (
            <ButtonSx
              onClick={this.handleSave}
              disabled={this.state.completed || this.state.isLoading}
              debounce
              variant="contained"
            >
              שמירה
            </ButtonSx>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = {
  gridItem: {
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  generalPaper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
};

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
  };
}

export default connect(mapStateToProps)(
  withBreakpoints("mdUp", EditSubscriptionProductDialog)
);
