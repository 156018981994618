import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import _ from "lodash";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";

import axios from "../../utils/axios";
import withRouter from "../../components/routing/withRouter";
import AbsoluteCenter from "../../components/AbsoluteCenter";

function SubscriptionsChart(props) {
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState({ names: [], dates: [] });
  const [isLoading, setIsLoading] = useState(false);

  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const res = await axios.get(`/store/subscriptions/statusNg`);

      const data = { ...res.data };
      Object.keys(data).forEach((key) => {
        data[key] = _.sortBy(data[key], (rec) => moment(rec.date).unix()).slice(
          isSmUp ? -14 : -7
        );
      });
      const clubs = props.selectedClub.superClub
        ? props.selectedClub.subClubs
        : [props.selectedClub];
      setSeries(
        Object.entries(data).map(([internalName, datum]) => {
          const club = clubs.find((c) => c.internalName === internalName);
          return {
            internalName: club.internalName,
            name:
              club.clubConfig.general.branchName ||
              club.clubConfig.general.name,
            data: datum.map((t) => t.count),
          };
        })
      );
      setLabels({
        names: Object.entries(data)[0][1]?.map((t) =>
          moment(t.date).format("DD/MM")
        ),
        dates: Object.entries(data)[0][1]?.map((t) => moment(t.date)),
      });

      setIsLoading(false);
    };
    load();
  }, []);

  const options = {
    chart: {
      height: 350,
      type: "bar",
      stacked: true,
      toolbar: { show: false },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const club = series[config.seriesIndex].internalName;
          const date = moment(labels.dates[config.dataPointIndex]);
          props.navigate(
            `../clubs/${club}/reports/subscriptionLogs?dateFrom=${date.toISOString()}&dateTo=${date.toISOString()}&logTypes=new&logTypes=newTeamChange`
          );
        },
      },
    },
    colors: [
      "#40a0fc",
      "#febc4b",
      "#50e7a6",
      "#ff6478",
      "#8c75d7",
      "#f44336",
      "#e91e63",
      "#9c27b0",
      "#00bcd4",
      "#009688",
      "#4caf50",
      "#cddc39",
      "#ff9800",
      "#795548",
    ],
    stroke: { width: 2 },
    dataLabels: { enabled: false },
    yaxis: {
      title: {
        text: "מספר נרשמים",
        offsetX: -60,
      },
      labels: {
        formatter: function (val) {
          return Math.floor(val);
        },
      },
    },
    title: {
      text: `רישום ספורטאים - ${isSmUp ? 14 : 7} הימים האחרונים`,
      align: "center",
    },
  };

  if (isLoading) {
    return (
      <Box sx={{ height: 100, position: "relative" }}>
        <AbsoluteCenter>
          <CircularProgress />
        </AbsoluteCenter>
      </Box>
    );
  }

  return (
    <Box>
      <div style={{ letterSpacing: "normal" }}>
        <Chart
          options={{
            ...options,
            xaxis: {
              ...options.xaxis,
              categories: labels.names,
            },
          }}
          series={series}
          type="bar"
          height={350}
        />
      </div>
    </Box>
  );
}

export default withRouter(SubscriptionsChart);
